.reviewBackground {
  background-image: url(../../img/100.png);
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 550px;
}

.reviewBackgroundMobile {
  padding: 10px;
  background-color: #f6ebff;
}

/* JobListingPage.css */
.job-listing-page .MuiButton-containedPrimary {
  background-color: #7c00de;
  color: white;
}

.job-listing-page .MuiButton-containedPrimary:hover {
  background-color: #5a00a6;
}

.custom-scroll {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.custom-scroll::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.custom-list {
  list-style-type: disc; /* Ensure bullets are shown */
  padding-left: 20px; /* Add padding to make space for bullets */
}

.customScroll::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Optional: just to remove any scrollbar background */
}

/* Hide vertical scrollbar for IE, Edge, and Firefox */
.customScroll {
  height: 800px; /* Set the desired height */
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.custom-list li {
  font-size: 16px;
  color: #515b6f; /* Gray color for text */
  position: relative; /* Positioning for custom bullet */
}

.custom-list p {
  font-size: 16px;
  color: #515b6f;
}

.custom-list p strong {
  font-size: 20px;
  color: #000000;
}

.custom-list li::before {
  content: "•"; /* Custom bullet character */
  color: #56cdad; /* Green color for bullets */
  position: absolute; /* Absolute positioning for the bullet */
  left: -20px; /* Adjust this to move the bullet to the left */
  font-size: 20px; /* Adjust size if needed */
  line-height: 1; /* Align bullet with text */
}
