.grad1.MuiButton-root{
        
      text-transform: capitalize ;
        /* background-image: linear-gradient(to right, rgb(188, 251, 105), rgb(38, 187, 172)); */
}
.dialog-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.head{
    font-family:'Courier New', Courier, monospace ;
    text-align: center;
}
.dialog-title img{
    animation:slide-down 4s linear infinite ;
    animation-direction: alternate;
}
@keyframes slide-down {
    0% {
            transform: translateY(-10%);
        }
    
        50% {
            transform: translateY(8%);
        }
    
        65% {
            transform: translateY(-4%);
        }
    
        80% {
            transform: translateY(4%);
        }
    
        95% {
            transform: translateY(-2%);
        }
    
        100% {
            transform: translateY(0%);
        }
    
}
.dialog-title h1{
animation: slide-down1 2s linear;
}

/* @keyframes slide-down1 {
    0% { 
        transform: translateY(-100%);
    }

    50% {
        transform: translateY(-50%);
    }

  

    100% {
        transform: translateY(0%);
    }

} */
