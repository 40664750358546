span {
  font-weight: bold;
  font-family: Poppins-Medium;
}
.adduser {
  padding: 15px 0px;
  /* display: flex;
  justify-content: space-between; */
  grid-template-columns: repeat(2, 0.5fr);
}
.adduser-btn-box {
  /* flex-grow: 1; */
  text-align: end;
  /* padding-top: 18px; */
}
.searchUser{
  padding: 15px 15px;
  display: flex;
  justify-content: space-between;
  text-align: end;
}
.adduser Button {
  transition: 0.3s;
}
.adduser Button:hover {
  background-color: #2294c5;
  opacity: 0.8;
}
.fieldLabel {
  font-size: 18;
  font-weight: bold;
  text-align: left;
}
.fieldValue {
  font-size: 18;
  font-weight: bold;
  text-align: right;
}
span.full-details {
  font-weight: bold;
  font-family: Barlow-Bold;
}
.align-text {
  display: grid;
  grid-template-columns: 40% 60%;
  gap: 10px;
  
}
.valuesUser{
  display: inline-block;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.valuesUser:hover {
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
}