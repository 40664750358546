.sub-Container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sub-main-grid {
  margin-top: -45px;
  width: 95%;
  height: 85%;
  /* background: #aaa; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.sub-main-grid > .flex-row-item {
  flex: 1 1 30%;
  height: 100%;
}
.flex-row-item {
  /* background-image: linear-gradient(45deg,#008ECC 50%, black 50%); */
  /* background-color: #fff4e6; */
  /* border: 1px solid #f76707; */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .sub-free {
  display: flex;
  transform: translate(150px, 0px);
  padding: 2px 0px 2px 0px;
} */

.subCards.MuiCard-root {
  width: 88%;
  height: 95%;
  border-radius: 50px 0px 50px 0px !important;
  border: 1px solid #bab8b8d7;
  text-align: center;
}

.subCards:hover {
  border-radius: 50px 0px 50px 0px;
  box-shadow: 0px 4px 8px rgba(43, 149, 255, 0.5);
  border: 1px solid #89c4ff;
}

@media only screen and (min-width: 250px) {
  .paymentSideImage.MuiGrid-root {
    display: none;
  }
  .paymentButton.MuiButton-root {
    width: 130px;
  }
}

@media only screen and (min-width: 300px) {
  .sub-free {
    display: flex;
    transform: translate(40px, 0px);
    padding: 2px 0px 2px 0px;
  }
}
@media only screen and (min-width: 1024px) {
}

@media only screen and (min-width: 400px) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
  .sub-free {
    display: flex;
    transform: translate(20px, 0px);
    padding: 2px 0px 2px 0px;
  }
}

@media only screen and (min-width: 540px) {
  .sub-free {
    display: flex;
    transform: translate(10px, 0px);
    padding: 2px 0px 2px 0px;
  }
}

@media only screen and (min-width: 912px) {
  .paymentSideImage.MuiGrid-root {
    display: block;
  }
  .paymentContentGrid.MuiGrid-root {
    padding: 60px;
  }
  .paymentButton.MuiButton-root {
    width: 150px;
  }
  .sub-free {
    display: flex;
    transform: translate(100px, 0px);
    padding: 2px 0px 2px 0px;
  }
}

@media only screen and (min-width: 1200px) {
  .sub-free {
    display: flex;
    transform: translate(150px, 0px);
    padding: 2px 0px 2px 0px;
  }
}
