.sub-Container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sub-main-grid {
    width: 95%;
    height: 85%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .sub-main-grid > .flex-row-item {
    flex: 1 1 30%;
    height: 100%;
  }
  .flex-row-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sub-cards1.MuiCard-root  {
    width: 88%;
    height: 400px;
    border-radius: 50px 0px 50px 0px;
    border: 1px solid #bab8b8d7;
  }      
 
  .sub-cards1:hover {
    border-radius: 50px 0px 50px 0px;
    box-shadow: 0px 4px 8px rgba(43, 149, 255, 0.5);
    border: 1px solid #89c4ff;
  }
  