.Avator {
  display: flex;
  flex-direction: row;
}

#EmpUpdscroll::-webkit-scrollbar {
  display: none;
}
.css-e29rmm {
  font-size: 20px;
  margin-bottom: 8px;
}

.Card {
  background-color: #fff;
  border: 1px solid black;
  box-shadow: 0 3px 10px rgba(6, 117, 162, 0.7);
  border-radius: 50px 10px 50px 10px;
}

.SideGrid {
  position: fixed;
  padding: 10px 0 0 10px;
}
.logoIcon {
  color: rgb(25 118 210);
  cursor: pointer;
  top: 50%;
  left: 150%;
  transform: translate(130%, 80%);
  position: absolute;
  background-color: white;
  border-radius: 50%;
}

a {
  text-decoration: none;
  color: black;
}

.full-content {
  background-color: rgba(6, 117, 162, 0.3);
}

#mobile_number::-webkit-inner-spin-button,
#mobile_number::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
#mobile_number {
  -moz-appearance: textfield;
}
#quickactive {
  color: #ed1e70;
}
#quickactive1 {
  color: #135c82;
}

.emplogo {
  width: 100px;
  height: 70px;
  margin: 0px 15px 0px 5px;
  border: 3px solid green;
}

@media only screen and (max-width: 600px) {
  .Avator {
    display: flex;
    flex-direction: column;
  }
  #EmpQuickLinkhide {
    display: none;
  }
}
@media only screen and (max-width: 800px) {
  #EmpQuickLinkhide {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  #EmpQuickLinkhide {
    display: none;
  }
}
@media only screen and (max-width: 900px) {
  #EmpQuickLinkhide {
    display: none;
  }
}

@media only screen and (min-width: 912px) {
  #EmpQuickLinkhide {
    display: block;
    width: 150px;
    height: 48rem;
    position: sticky;
    top: 0;
  }
}

@media only screen and (min-width: 1000px) {
  #EmpQuickLinkhide {
    display: block;
    height: 45rem;
    position: sticky;
    width: 150px;
    top: 0;
  }
}
@media only screen and (min-width: 1024px) {
  #EmpQuickLinkhide {
    display: block;
    height: 48rem;
    position: sticky;
    width: 150px;
    top: 0;
  }
}
@media only screen and (min-width: 1200px) {
  #EmpQuickLinkhide {
    display: block;
    height: 40rem;
    position: sticky;
    top: 0;
    width: 170px;
  }
}
.CircularProgress.MuiCircularProgress-root {
  margin-left: 3rem;
  margin-top: -3.4rem;
  font-size: 1.7rem;
}

@media only screen and (min-width: 1200px) {
  .CircularProgress.MuiCircularProgress-root {
    margin-left: 3rem;
    margin-top: -3.4rem;
    font-size: 1.7rem;
  }
  .logoIcon {
    color: rgb(25 118 210);
    cursor: pointer;
    top: 50%;
    left: 150%;
    transform: translate(130%, 80%);
    position: absolute;
    background-color: white;
    border-radius: 50%;
  }
  .emplogo {
    width: 100px;
    height: 70px;
    margin: 0px 15px 0px 5px;
    border: 3px solid green;
  }
}
/* .company-details {
  display: grid;
  width: 100%;
  grid-template-columns: 30% 70%;
  gap: 10px;
  font-size: 18px;
} */
/* span.company-details-font {
  font-weight: bold;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
} */
@media only screen and (max-width: 600px) {
  /* .company-details {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 10px;
    font-size: 16px;
  } */
  /* span.company-details-font {
    font-weight: bold;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  } */
}
.keypair {
  display: flex;
  width: 100%;
}
#companykey {
  font-weight: 600;
  width: 320px;
  margin-top: 10px;
}
#companyvalue {
  font-weight: 600;
  width: 700px;
  margin-top: 10px;
}

@media only screen and (max-width: 600px) {
  .keypair {
    display: block;
  }
  #none {
    display: none;
  }
  #companyvalue {
    width: auto;
  }
}
@media only screen and (max-width: 600px) {
  .CircularProgress.MuiCircularProgress-root {
    margin-left: -5rem;
    margin-top: 1.5rem;
    font-size: 1.7rem;
  }
  .logoIcon {
    color: rgb(25 118 210);
    cursor: pointer;
    top: 50%;
    left: 50%;
    transform: translate(70%, 80%);
    position: absolute;
    background-color: white;
    border-radius: 50%;
  }
  .emplogo {
    width: 100px;
    height: 70px;
    margin: 0px 15px 0px 5px;
    border: 3px solid green;
  }
}
@media only screen and (max-width: 550px) {
  .CircularProgress.MuiCircularProgress-root {
    margin-left: 2.9rem;
    margin-top: -3.2rem;
    font-size: 1.7rem;
  }
  .logoIcon {
    color: rgb(25 118 210);
    cursor: pointer;
    top: 50%;
    left: 50%;
    transform: translate(90%, 80%);
    position: absolute;
    background-color: white;
    border-radius: 50%;
  }
  .emplogo {
    width: 100px;
    height: 70px;
    margin: 0px 15px 0px 5px;
    border: 3px solid green;
  }
}
@media only screen and (max-width: 500px) {
  .CircularProgress.MuiCircularProgress-root {
    margin-left: 3rem;
    margin-top: -3.4rem;
    font-size: 1.7rem;
  }
  .logoIcon {
    color: rgb(25 118 210);
    cursor: pointer;
    top: 50%;
    left: 60%;
    transform: translate(90%, 80%);
    position: absolute;
    background-color: white;
    border-radius: 50%;
  }
  .emplogo {
    width: 100px;
    height: 70px;
    margin: 0px 15px 0px 5px;
    border: 3px solid green;
  }
}
@media only screen and (max-width: 450px) {
  .CircularProgress.MuiCircularProgress-root {
    margin-left: 3rem;
    margin-top: -3.4rem;
    font-size: 1.7rem;
  }
  .logoIcon {
    color: rgb(25 118 210);
    cursor: pointer;
    top: 50%;
    left: 70%;
    transform: translate(90%, 80%);
    position: absolute;
    background-color: white;
    border-radius: 50%;
  }
  .emplogo {
    width: 100px;
    height: 70px;
    margin: 0px 15px 0px 5px;
    border: 3px solid green;
  }
}
@media only screen and (max-width: 400px) {
  .CircularProgress.MuiCircularProgress-root {
    margin-left: 3rem;
    margin-top: -3.4rem;
    font-size: 1.7rem;
  }
  .logoIcon {
    color: rgb(25 118 210);
    cursor: pointer;
    top: 50%;
    left: 80%;
    transform: translate(90%, 80%);
    position: absolute;
    background-color: white;
    border-radius: 50%;
  }
  .emplogo {
    width: 100px;
    height: 70px;
    margin: 0px 15px 0px 5px;
    border: 3px solid green;
  }
}
@media only screen and (max-width: 350px) {
  .CircularProgress.MuiCircularProgress-root {
    margin-left: 3rem;
    margin-top: -3.4rem;
    font-size: 1.7rem;
  }
  .logoIcon {
    color: rgb(25 118 210);
    cursor: pointer;
    top: 50%;
    left: 100%;
    transform: translate(90%, 80%);
    position: absolute;
    background-color: white;
    border-radius: 50%;
  }
  .emplogo {
    width: 100px;
    height: 70px;
    margin: 0px 15px 0px 5px;
    border: 3px solid green;
  }
}
@media only screen and (max-width: 300px) {
  .CircularProgress.MuiCircularProgress-root {
    margin-left: 3rem;
    margin-top: -3.4rem;
    font-size: 1.7rem;
  }
  .logoIcon {
    color: rgb(25 118 210);
    cursor: pointer;
    top: 50%;
    left: 130%;
    transform: translate(90%, 80%);
    position: absolute;
    background-color: white;
    border-radius: 50%;
  }
  .emplogo {
    width: 100px;
    height: 70px;
    margin: 0px 15px 0px 5px;
    border: 3px solid green;
  }
}
