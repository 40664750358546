.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.logintext {
  color: #108fb2;
  background-color: white;
  padding: 5px 15px;
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}
.logintext:hover {
  opacity: 0.8;
  color: #ed1067;
}
.header-link-active {
  border-bottom: 3px solid #ed1167cc !important;
}

.header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 9999
}

.footerpart {
  background-color: #108fb2 !important;
}

.headerpart {
  background-image: url("../img/header.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.logout {
  margin: 0px 5px;
}
.footer {
  margin-bottom: -19px;
}
@media only screen and (max-width: 600px) {
  .header {
    position: sticky;
    top: 0;
    width: 100%;
  }
  .footer {
    margin-bottom: -20px;
  }
}

.contected {
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: scroll;
  flex: 1;
}

.contected::-webkit-scrollbar {
  width: 10px;
  transition: 0.5s;
}

.contected::-webkit-scrollbar-thumb {
  /* background-color: #108fb2; */
  border-radius: 5px;
  background-color: rgba(16, 143, 178, 0.3);
}


.logout {
  margin: 0px 5px;
}

@media only screen and (max-width: 600px) {
  .header {
    position: sticky;
    top: 0;
    width: 100%;
  }
}


/* ======== */

.login-content{
  position: relative;
}
.login-content p{
  font-family: "Poppins-Regular";
  font-size: 15px;
}
.login-content p text{
  color: #35cef8;
  /* text-decoration: underline; */
}
.login-content p text:hover{
   text-decoration: underline; 
   cursor: pointer;
}
.splite-content{
  display: flex;
  justify-content: space-around;
}
.login-content::after{
  content: "";
    height: 35px;
    width: 2px;
    position: absolute;
    left: -12px;
    top: -6px;
    background: #fff;
    opacity: 0.5;
}
.btn-header{
  width: 140px !important;
  height: 40px !important;
  padding: 10px 0px !important;
  font-family: "Poppins-Regular" !important;
  /* background-color: #ffffff !important;
  color: #ec2172 !important; */
  border: none !important;
  border-radius: 4px !important;
  /* transition: 0.2s !important; */
  cursor: pointer !important;
  text-transform: capitalize !important;
  position: relative;
  font-weight: 600 !important;
  overflow: hidden;
}
.menu-for-lagbtn{
  z-index: 99999 !important;
}
.logo-div-size{
  width: 68% 
}

@media (min-width: 980px) and (min-width: 1023px) { 
  .logo-div-size{
    width: 60% !important;
  }
 }

.dd{
  background-color: #fff !important;
  transition: 0.3s !important;
  color: #108fb2 !important;
}

.dd:hover{
  background-color: #fff !important;
  opacity: 0.8 !important;
}