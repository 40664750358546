.img-grid {
  position: sticky;
}
.sideimg {
  position: fixed;
  width: 250px;
  height: 100%;
  margin-top: 13vh;
}

.sideimglogo {
  display: flex;
  justify-content: center;
}

.listofsidecontect {
  display: flex;
  gap: 5px;
}
.sidecontect {
  width: 100%;
}

#mobile_number::-webkit-inner-spin-button,
#mobile_number::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
#mobile_number {
  -moz-appearance: textfield;
}

@media only screen and (min-width: 250px) {
  .dataBasePurchaseAutocomplete {
    margin-top: 8px;
    background-color: white;
    width: 200px;
  }
  .dataBasePurchaseTopDiv {
    display: grid;
    grid-template-columns: auto;
    margin-top: 20px;
    justify-content: center;
  }
  .dataBasePurchaseCard {
    margin-bottom: 20px;
    box-shadow: 4px 4px 20px 2px rgba(16, 143, 178, 0.4);
    height: 450px;
    width: 220px;
    border: 2px solid var(--blue, #108fb2);
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
  }

  .dataBasePurchaseCard:hover {
    box-shadow: 6px 6px 24px 4px rgba(16, 143, 178, 0.6);
    border: 2px solid var(--blue, #108fb2);
  }

  .dataBasePurchaseTime {
    display: block;
    margin-top: -15px;
    font-family: Poppins-Medium;
  }

  .dataBasePurchaseTime2 {
    margin-top: -10px;
  }

  .dataBasePurchaseTable {
    max-width: 250px;
    overflow: "auto";
  }

  .header {
    font-size: smaller;
  }
  .Box {
    flex-wrap: wrap;
  }
  .sideimg {
    display: none;
  }
  .successfulPurchaseImage {
    height: 300px;
    width: 250px;
  }
  .PurchaseFailImage {
    height: 250px;
    width: 200px;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 300px) {
  .dataBasePurchaseAutocomplete {
    margin-top: 8px;
    background-color: white;
    width: 280px;
  }
  .dataBasePurchaseTopDiv {
    display: grid;
    grid-template-columns: auto;
    margin-top: 20px;
    justify-content: center;
  }
  .dataBasePurchaseCard {
    margin-bottom: 20px;
    box-shadow: 4px 4px 20px 2px rgba(16, 143, 178, 0.4);
    height: 450px;
    width: 260px;
    border: 2px solid var(--blue, #108fb2);
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
  }

  .dataBasePurchaseCard:hover {
    box-shadow: 6px 6px 24px 4px rgba(16, 143, 178, 0.6);
    border: 2px solid var(--blue, #108fb2);
  }

  .dataBasePurchaseTime {
    display: block;
    margin-top: -15px;
    font-family: Poppins-Medium;
  }

  .dataBasePurchaseTime2 {
    margin-top: -10px;
  }
  .header {
    font-size: smaller;
  }

  .Box {
    flex-wrap: wrap;
  }

  .sideimg {
    display: none;
  }
  .successfulPurchaseImage {
    height: 300px;
    width: 280px;
    margin-top: -50px;
  }
  .PurchaseFailImage {
    height: 280px;
    width: 250px;
    margin-top: -20px;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 400px) {
  .dataBasePurchaseTable {
    max-width: 330px;
    overflow: "auto";
  }
  .dataBasePurchaseAutocomplete {
    margin-top: 8px;
    background-color: white;
    width: 350px;
  }
  .dataBasePurchaseTopDiv {
    display: grid;
    grid-template-columns: auto;
    margin-top: 20px;
    justify-content: center;
  }
  .dataBasePurchaseCard {
    margin-bottom: 20px;
    box-shadow: 4px 4px 20px 2px rgba(16, 143, 178, 0.4);
    height: 420px;
    width: 300px;
    border: 2px solid var(--blue, #108fb2);
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
  }

  .dataBasePurchaseCard:hover {
    box-shadow: 6px 6px 24px 4px rgba(16, 143, 178, 0.6);
    border: 2px solid var(--blue, #108fb2);
  }

  .dataBasePurchaseTime {
    display: flex;
    justify-content: space-between;
    margin-top: -15px;
    font-family: Poppins-Medium;
  }

  .dataBasePurchaseTime2 {
    margin-top: 15px;
  }
  .header {
    font-size: small;
  }
  .Box {
    flex-wrap: wrap;
  }

  .sideimg {
    display: none;
  }
  .successfulPurchaseImage {
    height: 420px;
    width: 380px;
    margin-top: -60px;
  }
  .PurchaseFailImage {
    height: 350px;
    width: 325px;
    margin-top: -20px;
    margin-bottom: 10px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .dataBasePurchaseTable {
    max-width: 450px;
    overflow: "auto";
  }
  .header {
    font-size: medium;
  }

  .Box {
    flex-wrap: nowrap;
  }

  .sideimg {
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .dataBasePurchaseTable {
    max-width: 500px;
    overflow: "auto";
  }
  .dataBasePurchaseAutocomplete {
    margin-top: 8px;
    background-color: white;
    width: 500px;
  }
  .dataBasePurchaseTopDiv {
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 20px;
    justify-content: space-between;
  }
  .dataBasePurchaseCard {
    margin-bottom: 20px;
    box-shadow: 4px 4px 20px 2px rgba(16, 143, 178, 0.4);
    height: 450px;
    width: 230px;
    border: 2px solid var(--blue, #108fb2);
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
  }

  .dataBasePurchaseCard:hover {
    box-shadow: 6px 6px 24px 4px rgba(16, 143, 178, 0.6);
    border: 2px solid var(--blue, #108fb2);
  }

  .dataBasePurchaseTime {
    display: block;
    margin-top: -15px;
    font-family: Poppins-Medium;
  }

  .dataBasePurchaseTime2 {
    margin-top: -10px;
  }
  .header {
    font-size: large;
  }

  .Box {
    flex-wrap: nowrap;
  }
  .successfulPurchaseImage {
    height: 480px;
    width: 450px;
    margin-top: -90px;
  }
  .PurchaseFailImage {
    height: 400px;
    width: 385px;
    margin-top: -20px;
    margin-bottom: 10px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 912px) {
  .dataBasePurchaseTopDiv {
    display: grid;
    grid-template-columns: auto auto auto;
    margin-top: 20px;
  }
  .dataBasePurchaseCard {
    margin-bottom: 20px;
    box-shadow: 4px 4px 20px 2px rgba(16, 143, 178, 0.4);
    height: 450px;
    width: 230px;
    border: 2px solid var(--blue, #108fb2);
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
  }

  .dataBasePurchaseCard:hover {
    box-shadow: 6px 6px 24px 4px rgba(16, 143, 178, 0.6);
    border: 2px solid var(--blue, #108fb2);
  }

  .dataBasePurchaseTime {
    display: block;
    margin-top: -15px;
    font-family: Poppins-Medium;
  }

  .dataBasePurchaseTime2 {
    margin-top: -10px;
  }

  .header {
    font-size: larger;
  }
  .sideimg {
    position: fixed;
    display: block;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 100%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .dataBasePurchaseTopDiv {
    display: grid;
    grid-template-columns: auto auto auto;
    margin-top: 20px;
  }
  .dataBasePurchaseCard {
    margin-bottom: 20px;
    box-shadow: 4px 4px 20px 2px rgba(16, 143, 178, 0.4);
    height: 410px;
    width: 300px;
    border: 2px solid var(--blue, #108fb2);
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
  }

  .dataBasePurchaseCard:hover {
    box-shadow: 6px 6px 24px 4px rgba(16, 143, 178, 0.6);
    border: 2px solid var(--blue, #108fb2);
  }
  .dataBasePurchaseTime {
    display: flex;
    justify-content: space-between;
    margin-top: -33px;
    font-family: Poppins-Medium;
  }

  .dataBasePurchaseTime2 {
    margin-top: 15px;
  }

  .header {
    font-size: larger;
  }
  .Box {
    flex-wrap: nowrap;
  }
  .successfulPurchaseImage {
    height: 480px;
    width: 465px;
    margin-top: -90px;
  }
  .PurchaseFailImage {
    height: 400px;
    width: 385px;
    margin-top: -20px;
    margin-bottom: 10px;
  }
}
