.VideoInput {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;
    
  }
  
  .VideoInput_input {
    display: none;
    
  }
  
  .VideoInput_video {
    display: block;
    margin: 0;
    
  }
  
  .VideoInput_footer {
    background: #eee;
    width: 100%;
    min-height: 20px;
    line-height: 20px;
    text-align: center;
  }
  