.form-box.MuiBox-root::-webkit-scrollbar {
  width: 5px;
  transition: 0.5s;
}

.form-box.MuiBox-root::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #005e82;
}
#mobile_number::-webkit-inner-spin-button,
#mobile_number::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

#mobile_number {
  -moz-appearance: textfield;
}
