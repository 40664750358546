@media only screen and (min-width: 250px) {
   
    .largeDeviceLoacalJob{
      display: none;
    }
    .mobileDeviceLocalJobs{
      display: block;
    }
}

@media only screen and (min-width: 300px) {
  
}

@media only screen and (min-width: 350px) {
  
  .LocalJobsAlignment{
      display: grid;
      grid-template-columns: 60% 40%;
    }
  .LocalJobsNameAlignment{
    display: flex;
    justify-content: space-between;
  }
 
}

@media only screen and (min-width: 400px) {
  .LocalJobsAlignment{
      display: grid;
      grid-template-columns: 50% 50%;
    }

    .LocalJobsNameAlignment{
      display: flex;
      justify-content: space-between;
    }
 
}


@media only screen and (min-width: 600px) {
  .largeDeviceLoacalJob{
      display: block;
  }
  
}


@media only screen and (min-width: 768px) {
  .LocalJobsAlignment{
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .LocalJobsNameAlignment{
    display: flex;
    justify-content: space-between;
  }
  
}


@media only screen and (min-width: 912px) {
  .LocalJobsAlignment{
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .LocalJobsNameAlignment{
    display: flex;
    justify-content: space-between;
  }
  
}


@media only screen and (min-width: 1200px) {

  .LocalJobsAlignment{
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .LocalJobsNameAlignment{
    display: flex;
    justify-content: space-between;
  }

  
}

