#mobile_number::-webkit-inner-spin-button,
#mobile_number::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
#mobile_number {
  -moz-appearance: textfield;
}

.mentorProfileImage {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 2px solid rgb(38, 108, 240);
}

.ImageOuterMentor {
  padding: 1em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.MentorProfile {
  height: 100px;
  width: 100px;
}

.ImageCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardShadow {
  max-width: 345px;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(45, 45, 46, 0.7);
}

/* .item {
  background: #fff;
  padding: 60px 0;
  overflow: hidden;
} */

.member {
  overflow: hidden;
  text-align: center;
  background: #fff;
  transition: 0.3s;
  border-radius: 10px 10px 0px 0px;
}
.mentorcard {
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(107, 108, 109, 0.7);
  width: 80%;
  transition: 0.5s;
  cursor: pointer;
  /* height: 450px; */
  min-height: 440px;
  max-height: 650px;
}

.mentorcard:hover {
  box-shadow: 0px 0px 8px 2px rgba(16, 143, 178, 0.7);
  transform: scale(1.08);
}

.member {
  position: relative;
  overflow: hidden;
}

.member-img {
  width: 100%;
  height: 220px;
  border-radius: 10px 10px 0px 0px;
}

.member::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background: url(../../../img/team-shape.svg) no-repeat center bottom;
  background-size: contain;
  z-index: 1;
}

.member-info {
  text-align: center;
  background-color: #ffffff;
}

.member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 20px;
  color: #03576f;
  margin: 0;
  text-transform: uppercase;
}
.member-info span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #aaaaaa;
}
.member-info p {
  font-style: italic;
  font-size: 14px;
  margin: 3px;
  margin-bottom: 8px;
  line-height: 26px;
  color: #5e5e5e;
}

.social {
  position: absolute;
  top: 88%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 111;
  background-color: rgba(245, 245, 245, 0.6);
  box-shadow: 0px 0px 2px 2px #108fb2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}

.socialLinkedInIcon:hover {
  background-color: #0077b5;
  color: white;
}

.MentorImageContent {
  width: 100%;
  min-width: 100px;
  min-height: 100px;
  margin-top: 10px;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MentorImageBorder {
  border: 3px dashed #cbd5e0;
  background-color: rgba(166, 199, 212, 0.1);
  width: 50%;
  min-width: 100px;
  min-height: 100px;
  margin-top: 10px;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.RenderImageContent {
  width: 100%;
  min-width: 100px;
  min-height: 100px;
  margin-top: 10px;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RenderImageBorder {
  border: 1px solid #919192;
}

.MentorDelete {
  text-align: center;
  margin-top: -5px;
}

@media only screen and (min-width: 250px) {
  .MentorGrid {
    display: grid;
    grid-template-columns: 100%;
  }
  .header {
    font-size: smaller;
  }
  .Box {
    flex-wrap: wrap;
  }
  .sideimg {
    display: none;
  }
}

@media only screen and (min-width: 300px) {
  .header {
    font-size: smaller;
  }

  .Box {
    flex-wrap: wrap;
  }

  .sideimg {
    display: none;
  }
}

@media only screen and (min-width: 400px) {
  .header {
    font-size: small;
  }
  .Box {
    flex-wrap: wrap;
  }

  .sideimg {
    display: none;
  }
}

@media only screen and (min-width: 540px) {
  .MentorGrid {
    display: grid;
    grid-template-columns: 50% 50%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .header {
    font-size: medium;
  }

  .Box {
    flex-wrap: nowrap;
  }

  .sideimg {
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .MentorGrid {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .header {
    font-size: large;
  }

  .Box {
    flex-wrap: nowrap;
  }

  /* .sideReg{ 
    display: none;
   } */
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 912px) {
  .header {
    font-size: larger;
  }
  .sideimg {
    position: fixed;
    display: block;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 100%;
  }
  .MentorGrid {
    display: grid;

    grid-template-columns: 25% 25% 25% 25%;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .header {
    font-size: larger;
  }
  .Box {
    flex-wrap: nowrap;
  }
}

.countrating {
  margin-top: 8px;
  font-weight: 500;
}

.RateThisMentor {
  font-weight: 500;
  font-size: 16px;
}
.about-header-mentor{
  font-size: 20px;
    font-weight: 700;
    color: #108fb2;
    font-family: "Poppins-SemiBold";
    text-transform: uppercase;
   
}


.fristdiv-for-rating {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding-top: 10px;
}

.fristdiv-for-rating .ratingcount{
 display: flex;
 justify-content: center;
 align-items: center;
}

.ratingnumber{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.joindate{
  margin-top: 10px;
}

.secounddiv-for-rating{
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 10px;
  padding: 8px 0px ;
  background-color: #5b5d5e;
  border-radius: 0px 0px 10px 10px;
  cursor: not-allowed;
}

.mentoraboutuspageheader {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-image: url(../../../img/Polygon\ Luminary\ \(1\).svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 0px;
}

.backbuttonformentor{
  width: 95%;
  padding:3px 10px;
  display: flex;
  justify-content: space-between;
  color: white;
}

.backbuttonformentorUpdate{
  background-color: #339fcd;
}
.backbuttonformentorUpdate.MuiIconButton-root:hover{
  background-color: #0c5372;
}

.editbutton-mentor:hover{
  color: white;
}
.editbutton-mentor:hover .iconedit{
  color: white !important;
}

.mentorpage {
  width: 80%;
  display: grid;
  grid-template-columns: 15% 70%;
  gap: 20px;
}
.mentorpage-imgpart .mentorpage-img {
  width: 100%;
  border-radius: 50%;
  border: 5px solid rgba(245, 245, 245, 0.3);
  
}
.mentorpage-imgpart .text{
  position: absolute;
  top: 50%;
  left: 50%;
  height: 94%;
  width: 100%;
  opacity: 0;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color:rgba(16, 143, 178, 0.3);
  display: flex;
  justify-content: center;
  align-items: end;
  transition: 0.3s;
}



.mentorpage-imgpart {
  display: flex;
  justify-content: center;
  position: relative;
  
}

.mentorpage-imgpart:hover .text{
opacity: 1;
}


.Mentorinfo {
  display: flex;
  gap: 20px;
}
.mentorname {
  padding: 10px 0px;
  padding-top: 31px;
  margin-left: 10px;
}
.mentorname h4 {
  font-size: 26px;
  font-weight: 700;
  color: white;
  margin-bottom: 5px;
  font-family: "Poppins-SemiBold";
  margin: 5px 0px;
  margin-bottom: 0px;
}
.Mentorinfo p {
  margin-top: 3px;
  color: white;
  margin-bottom: 5px;
  font-family: "Barlow-Medium";
}

.MentorBox {
  margin-top: -20px;
}
.mentorbox-upperbox {
  display: grid;
  grid-template-columns: 65% 35%;
  
}
.mentor-fristcontect {
  padding: 0px 20px 10px 20px;
}
.combianbox {
  box-shadow: 0px 0px 8px 2px rgba(45, 45, 46, 0.2);
  border-radius: 10px;
  padding: 10px 10px;
  background-color: white;
  border-bottom:2px solid rgba(16, 143, 178,0.7) ;
}

.marginreduce{
  margin: 3px 0px ;
  font-weight: 600;
  font-size: medium;
  word-break: break-word;
  padding: 0px 2px;
}

.spanmentor{
  font-weight: normal;
  font-size: small;
  text-transform: uppercase;
  color: #5d5a5d;
}

.secondcount{
  margin-top: 10px;
}


.secoundbox {
  background-color: #77797a;
  color: rgb(241, 239, 239);
  padding: 0;
  border-bottom: none;
  cursor: not-allowed;
}

.crad-contect-mentor{
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  padding: 10px;
}

.mentorCVUpload{
  box-shadow: 0px 0px 8px 2px rgba(45, 45, 46, 0.2);
  border-radius: 10px;
  padding: 10px 10px;
  background-color: white;
  border-bottom:2px solid rgba(16, 143, 178,0.7) ;
  margin-top: 10px;
}

.CVUpload-card1{
 padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px dashed #cbd5e0;
    background-color: rgba(166, 199, 212, 0.1);
    min-width: 100px;
    min-height: 100px;
}

.uploadbox1 {
  margin-top: -12px;
  width: 100%;
  text-align: center;
}

.uploadbox1 p {
  padding: 0;
  margin: 0;
  color: #7a7979;
}

@media only screen and (max-width: 600px) { 
  .mentorbox-upperbox {
    display: grid;
    grid-template-columns:100%;
  }
  .mentorpage{
    display: grid;
    grid-template-columns:100%;
  }
  .Mentorinfo{
    display: flex;
    flex-direction: column;
  }
  .crad-contect-mentor{
    display: grid;
    grid-template-columns: 100%;
  }
  .mentorpage-imgpart .mentorpage-img{
    width: 50%;
  }
  .mentorpage-imgpart .text{
    width: 50%;
  }
}

@media only screen and (max-width: 820px){
  .mentorbox-upperbox {
    display: grid;
    grid-template-columns:100%;
  }
  .mentorpage{
    display: grid;
    grid-template-columns:100%;
  }
  .Mentorinfo{
    display: flex;
    flex-direction: column;
  }
  .crad-contect-mentor{
    display: grid;
    grid-template-columns: 100%;
  }
  .mentorpage-imgpart .mentorpage-img{
    width: 50%;
  }
  .mentorpage-imgpart .text{
    width: 50%;
  }
}

/* mentorabout */


.scrollable-container-Mentor {
  max-height: 150px;
  overflow-y: scroll;
}
/* Hide scrollbar for heights less than 180px */
@media (max-height: 180px) {
  .scrollable-container-Mentor::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }
}
/* Show scrollbar for heights more than 180px */
@media (min-height: 181px) {
  .scrollable-container-Mentor::-webkit-scrollbar {
    width: 0.4em;
    background-color: #f1f1f1;
  }
  .scrollable-container-Mentor::-webkit-scrollbar-thumb {
    background-color: rgb(53, 52, 52);
  }
}
